import { captureException } from '@sentry/nextjs';
import { Listbox, Menu, Transition } from '@headlessui/react';
import { CheckIcon, DotsVerticalIcon, SelectorIcon, SearchIcon, SortAscendingIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, SuccessToast } from 'components';
import { ErrorToastError } from 'components/Toast/ErrorToast';
import { DELETE_COMPANY_USER } from 'data/company';
import { DELETE_INVITE, UPDATE_USER_INVITE_ROLE } from 'data/invites';
import { UPDATE_USER_ROLE } from 'data/roles';
import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import button from 'styles/button';
import { StripeSubscription } from 'types/stripe';
import { OperationContext, useMutation, CombinedError } from 'urql';
import { classNames, syncStripeSeatCount } from 'utils';
import { EditUserNameDialog, WGProfileDialog, VOPSProfileDialog } from 'components';
import { WGAssessment } from 'components';
import { HelpInfo } from 'components/HelpInfo';
import OtherAssessmentsDialog from 'components/OtherAssessmentsDialog';

export type User = {
  id?: number;
  name?: string;
  image?: string;
  email?: string;
  accounts?: Account[];
};

export type Role = {
  id: number;
  name: RoleTypes;
};

export enum RoleTypes {
  COACH = 'Coach',
  ADMIN = 'Admin',
  MEMBER = 'Member',
}

export type PendingUser = {
  id: number;
  email: string;
  role: Role;
};

export type CompanyUser = {
  users_team_count?: Array<unknown>;
  id?: number;
  user_id?: number;
  user?: User;
  role?: Role;
  pending?: boolean;
  leave_company?: boolean;
  wg_skills?: WGSkills;
  vops_skills?: VOPSSkills;
  other_assessments?: Array<string>;
};

export type VOPSSkills = {
  visionary: number;
  operator: number;
  processor: number;
  synergist: number;
};

export type WGSkills = {
  wonder: string;
  invention: string;
  discernment: string;
  galvanizing: string;
  enablement: string;
  tenacity: string;
};

export type Account = {
  id?: number;
  provider_type?: string;
};

export const genuisAreas = [
  { id: '1', name: 'Area', color: '' },
  { id: '2', name: 'Genius', color: '#00a049' },
  { id: '3', name: 'Competency', color: '#fbc331' },
  { id: '4', name: 'Frustration', color: '#cb695b' },
];

export const skillTypes = [
  { id: '1', name: 'Working Genius' },
  { id: '2', name: ' VOPS - Predictable Success' },
  { id: '3', name: 'Other Assessments' },
];

export function getVOPSValue(vopsSkills: VOPSSkills): string {
  const scores = vopsSkills ? Object.values(vopsSkills) : [];
  const scoreValues = scores.map((i) => (i === null ? 0 : Number(i)));
  const maxScore = Math.max(...scoreValues.filter((score) => score > 240));

  let capsLetters = '';
  let simpleLetters = '';

  if (vopsSkills) {
    const majorScores = [];
    const minorScores = [];
    Object.entries(vopsSkills).filter(([key, value]) => {
      if (Number(value) === maxScore) {
        majorScores.push(key);
      } else if (Number(value) >= 240 && Number(value) != maxScore) {
        minorScores.push(key);
      }
    });
    for (let i = 0; i < majorScores?.length; i++) {
      capsLetters = capsLetters.concat(majorScores[i]?.charAt(0).toUpperCase());
    }
    for (let i = 0; i < minorScores?.length; i++) {
      simpleLetters = simpleLetters.concat(minorScores[i]?.charAt(0));
    }
  }

  let vopsScoreValue = null;
  if (capsLetters.length > 0 && simpleLetters.length > 0) {
    vopsScoreValue = capsLetters.concat('/').concat(simpleLetters);
  } else if (capsLetters.length > 0 || simpleLetters.length > 0) {
    vopsScoreValue = capsLetters || simpleLetters;
  }
  return vopsScoreValue;
}

type UsersListProps = {
  users: CompanyUser[];
  pendingUsers: PendingUser[];
  roles: Role[];
  companyId: string | number;
  refetchUsers: (opts?: Partial<OperationContext>) => void;
  canEdit?: boolean;
  canRemove?: boolean;
  subscription?: StripeSubscription;
  profileData?: { enabledWGProfile: boolean; enabledVOPSProfile: boolean };
  enabledAssessments?: boolean;
};

export default function UsersList({
  canEdit,
  canRemove,
  users,
  pendingUsers,
  roles,
  companyId,
  refetchUsers,
  subscription,
  profileData,
  enabledAssessments,
}: UsersListProps): ReactElement {
  const [error, setError] = useState<ErrorToastError>();

  const [, updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [, updateUserInviteRole] = useMutation(UPDATE_USER_INVITE_ROLE);
  const [, deleteCompanyUser] = useMutation(DELETE_COMPANY_USER);
  const [, deleteInvite] = useMutation(DELETE_INVITE);
  const [editingUserName, setEditingUserName] = useState(false);
  const [userData, setUserData] = useState(null);
  const [updated, setUpdated] = useState(null);

  const [userInfo, setUserInfo] = useState(null);
  const [wgDialogOpen, setWGDialogOpen] = useState(false);
  const [selectedWGSkills, setSelectedWGSkills] = useState({});
  const [vopsDialogOpen, setVOPSDialogOpen] = useState(false);
  const [vopsScore, setVOPSScore] = useState({});
  const [otherAssessmentsDialogOpen, setotherAssessmentsDialogOpen] = useState(false);
  const [otherAssessments, setOtherAssessments] = useState(['']);

  const companyUsersEmail = users.map(({ user }) => user?.email) || [];
  const allUsers: CompanyUser[] = [
    ...users,
    ...pendingUsers
      ?.filter(({ email }) => !companyUsersEmail.includes(email))
      .map(({ id, email, role }) => ({
        id,
        user: { id, email },
        role,
        pending: true,
      })),
  ].sort((a, b) => a.user.email.localeCompare(b.user.email));

  const [userList, setUserList] = useState(allUsers);

  const nonCoaches = useMemo(
    () =>
      [
        ...(users?.map((company_user) => ({ ...company_user, email: company_user.user.email })) || []),
        ...(pendingUsers || []),
      ].filter((user) => user.role.name !== 'Coach'),
    [users, pendingUsers],
  );
  const subscriptionItem = subscription?.items?.data?.[0];
  const quantity = subscriptionItem?.quantity || 0;
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [sortOption, setSortOption] = useState('Disabled');
  const [searchTerm, setSearchTerm] = useState('');

  const toggleSearch = () => {
    setIsSearchVisible((prev) => !prev);
  };

  const handleSortClick = () => {
    // Cycle through sorting options
    const nextSortOptions = {
      Disabled: 'By Email',
      'By Email': 'By Name',
      'By Name': 'By Status',
      'By Status': 'Disabled',
    };
    setSortOption(nextSortOptions[sortOption]);
  };

  // useEffect(() => {
  //   const updateSeatsCount = async () => {
  //     await syncStripeSeatCount(quantity, nonCoaches.length, companyId);
  //   };

  //   updateSeatsCount();
  // }, [refetchUsers, quantity, nonCoaches, companyId]);

  const syncSeatCount = useCallback(async () => {
    if (quantity !== nonCoaches.length) {
      await syncStripeSeatCount(quantity, nonCoaches.length, companyId);
    }
  }, [quantity, nonCoaches.length, companyId]);

  useEffect(() => {
    syncSeatCount();
  }, [syncSeatCount, refetchUsers]);

  // update the number of seats in stripe, needs to be refactored

  const menuItems = [];

  menuItems.push({
    label: 'Edit Skills',
    onClick: async ({ id, name, wg_skills, vops_skills, other_assessments }) => {
      setUserInfo({ id, name });
      setSelectedWGSkills(
        wg_skills || {
          wonder: '1',
          invention: '1',
          discernment: '1',
          galvanizing: '1',
          enablement: '1',
          tenacity: '1',
        },
      );
      setVOPSScore(vops_skills);
      setOtherAssessments(other_assessments || ['']);

      profileData.enabledWGProfile ? setWGDialogOpen(true) : setVOPSDialogOpen(true);
    },
  });

  if (canRemove) {
    menuItems.push({
      label: 'Remove',
      onClick: async ({ pending, id }) => {
        let err: CombinedError;
        if (pending) {
          const { error: deleteError } = await deleteInvite({ id });
          err = deleteError;
        } else {
          const { error: deleteError } = await deleteCompanyUser({ id });

          err = deleteError;
        }
        if (err) {
          captureException(err);
        }

        refetchUsers({ variables: { companyId } });
      },
    });
  }

  if (canEdit) {
    menuItems.push({
      label: 'Edit Name',
      onClick: async ({ email, name, _id: id }) => {
        setEditingUserName(true);
        setUserData({ email, name, id });
      },
    });

    menuItems.push({
      label: 'Reinvite',
      onClick: async ({ email, userRoleId }) => {
        const data = [
          {
            company_id: Number(companyId),
            role_id: userRoleId,
            email: email,
          },
        ];
        await fetch('/api/email/invite?reinvite=true', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ objects: data }),
        });
        setUpdated('Invitation Sent');
      },
    });

    menuItems.push({
      label: 'Copy Invite',
      onClick: async ({ email }) => {
        const inviteResponse = await fetch('/api/invite/copy-invite', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              companyId: Number(companyId),
              email: email,
            },
          }),
        });
        const invite = await inviteResponse.json();
        navigator.clipboard.writeText(invite?.link);
        if (invite?.link) {
          setUpdated('Invite link copied');
        } else {
          setError({ name: 'Error', message: 'Failed to copy invite link' });
        }
      },
    });
  }

  useEffect(() => {
    if (!searchTerm) {
      // To avoid unlimited state updates
      if (JSON.stringify(userList) !== JSON.stringify(allUsers)) {
        setUserList(allUsers);
      }
    } else {
      setUserList(
        allUsers.filter((user) => {
          if (user?.user?.email?.toLowerCase().includes(searchTerm)) {
            return user;
          } else if (user?.user?.name?.toLowerCase().includes(searchTerm)) {
            return user;
          }
        }),
      );
    }
  }, [searchTerm, allUsers]);

  return (
    <>
      <div className="mb-4">
        <div className="bg-blue-100 rounded-lg py-5 px-6 text-base text-gray-500 mb-3 flex" role="alert">
          <span className="flex-grow">
            Admin, member, and pending users are being charged. You are currently being charged for {nonCoaches.length}{' '}
            user{nonCoaches.length === 1 ? '' : 's'}.
            <HelpInfo
              informationToShow={
                <>
                  <p>
                    <strong>User-friendly Pricing Tiers:</strong>
                  </p>
                  <ul className="list-disc mt-2">
                    <li>
                      <strong>1-6 Users: $90/month</strong>
                      <ul>
                        <li>Ideal for smaller teams</li>
                        <li>Includes up to 6 users at a flat rate</li>
                      </ul>
                    </li>
                    <li>
                      <strong>7-25 Users: $9/user/month</strong>
                      <ul>
                        <li>For growing teams</li>
                        <li>Add users beyond 6 for just $9 each per month</li>
                      </ul>
                    </li>
                    <li>
                      <strong>26+ Users: $6/user/month</strong>
                      <ul>
                        <li>Great for larger teams</li>
                        <li>Scale your user count beyond 25 at a reduced rate of $6 per additional user per month</li>
                      </ul>
                    </li>
                    <li>
                      <strong>50+ Users: Contact us for tailored pricing</strong>
                      <ul>
                        <li>Customized plans available</li>
                        <li>Get in touch for personalized options for larger organizations</li>
                      </ul>
                    </li>
                  </ul>
                  <hr className="mt-4 mb-4" />
                  <ul className="list-disc text-gray-800">
                    <li>
                      <strong>NOTE:</strong> Your Coach user access will not be charged and is not counted toward your
                      number of users.
                    </li>
                  </ul>
                </>
              }
            />
          </span>
          <div className="flex items-center">
            <input
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              type="text"
              placeholder="Search..."
              className={`border rounded-lg py-1 mr-2 transition-opacity duration-300 ease-in-out ${
                isSearchVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
              }`}
            />
            <button onClick={toggleSearch} className="focus:outline-none">
              <SearchIcon className="h-5 w-5 text-right" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="flex flex-col divide-y divide-gray-200">
                {userList.map(
                  ({ id, user, role: userRole, pending, leave_company, wg_skills, vops_skills, other_assessments }) => {
                    const emailBaseAuth =
                      user.accounts?.filter((account: Account) => account?.provider_type === 'email').length > 0;
                    const vopsValue = getVOPSValue(vops_skills);
                    return (
                      <tr key={user.email} className="flex justify-between items-center">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex">
                            <Avatar user={user} />
                            <div className="ml-4">
                              <div
                                className={classNames(pending ? 'italic' : '', 'text-sm font-semibold text-gray-900')}
                              >
                                {pending ? 'Pending user' : user.name}
                              </div>
                              <div className={classNames(pending ? 'italic' : '', 'text-sm text-gray-500')}>
                                {user.email}
                              </div>
                              {leave_company ? (
                                <div className="text-sm font-semibold italic text-primary-700">Deactivated user</div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          {!pending && enabledAssessments && (
                            <div className="h-10 flex divide-x divide-gray-400 ml-9">
                              {wg_skills && <WGAssessment wgSkills={wg_skills} />}
                              {vops_skills && (
                                <div className="text-black font-bold my-2 text-xs">
                                  <div className="mx-2 my-1">{vopsValue}</div>
                                </div>
                              )}
                            </div>
                          )}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center justify-end">
                          {canEdit && !pending ? (
                            <Listbox
                              value={userRole.id}
                              onChange={async (roleId) => {
                                if (pending) {
                                  await updateUserInviteRole({
                                    pk_columns: { id: user.id },
                                    role_id: roleId,
                                  });
                                } else {
                                  await updateUserRole({
                                    role_id: roleId,
                                    user_id: user.id,
                                    company_id: companyId,
                                  });
                                }

                                refetchUsers({ variables: { companyId } });
                              }}
                            >
                              {({ open }) => (
                                <>
                                  <div className="mt-1">
                                    <Listbox.Button
                                      className={classNames(
                                        button.white(),
                                        'relative w-36 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm',
                                      )}
                                    >
                                      <span className="block truncate">{userRole.name}</span>
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options
                                        static
                                        className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                      >
                                        {roles.map((role) => (
                                          <Listbox.Option
                                            key={role.id}
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'text-white bg-primary' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3 pr-9',
                                              )
                                            }
                                            value={role.id}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <span
                                                  className={classNames(
                                                    selected ? 'font-semibold' : 'font-normal',
                                                    'block truncate',
                                                  )}
                                                >
                                                  {role.name}
                                                </span>

                                                {selected ? (
                                                  <span
                                                    className={classNames(
                                                      active ? 'text-white' : 'text-primary',
                                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    )}
                                                  >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          ) : (
                            <div className="relative inline-block text-left mx-3">{userRole.name}</div>
                          )}
                          {menuItems.length ? (
                            <Menu as="div" className="inline-block text-left mx-3">
                              {({ open }) => (
                                <>
                                  <div>
                                    <Menu.Button className="flex items-center">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      {menuItems.map((menuItem) => {
                                        let displayMenuItem = false;
                                        let params = {};
                                        const { email, name, id: _id } = user;
                                        const userRoleId = userRole.id;
                                        switch (menuItem.label) {
                                          case 'Edit Skills':
                                            !pending && enabledAssessments
                                              ? (displayMenuItem = true)
                                              : (displayMenuItem = false);
                                            params = { id, name, wg_skills, vops_skills, other_assessments };
                                            break;
                                          case 'Remove':
                                            displayMenuItem = true;
                                            params = { pending, id };
                                            break;
                                          case 'Reinvite':
                                            leave_company || pending
                                              ? (displayMenuItem = true)
                                              : (displayMenuItem = false);
                                            params = { email, userRoleId };
                                            break;
                                          case 'Edit Name':
                                            emailBaseAuth && !pending
                                              ? (displayMenuItem = true)
                                              : (displayMenuItem = false);
                                            params = { email, name, _id };
                                            break;
                                          case 'Copy Invite':
                                            pending ? (displayMenuItem = true) : (displayMenuItem = false);
                                            params = { email };
                                            break;
                                          default:
                                            break;
                                        }
                                        return displayMenuItem ? (
                                          <div className="py-1" key={menuItem.label}>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  type="button"
                                                  className={`block px-4 py-2 text-sm text-left w-full ${
                                                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                  }`}
                                                  onClick={async () => {
                                                    await menuItem.onClick(params);
                                                  }}
                                                >
                                                  {menuItem.label}
                                                </button>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        ) : null;
                                      })}
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          ) : null}
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        </div>
        <ErrorToast error={error} setError={setError} />
        <SuccessToast message={updated} setMessage={setUpdated} />
        <EditUserNameDialog
          open={editingUserName}
          setOpen={setEditingUserName}
          user={userData}
          setError={setError}
          setUpdated={setUpdated}
          refetchUsers={refetchUsers}
        />
        <WGProfileDialog
          open={wgDialogOpen}
          setOpen={setWGDialogOpen}
          enabledVOPSProfile={enabledAssessments}
          selectedWGSkills={selectedWGSkills}
          setSelectedWGSkills={setSelectedWGSkills}
          userInfo={userInfo}
          refetchUsers={refetchUsers}
          setVOPSDialogOpen={setVOPSDialogOpen}
          setOtherAssessmentsDialogOpen={setotherAssessmentsDialogOpen}
        />
        <VOPSProfileDialog
          open={vopsDialogOpen}
          setOpen={setVOPSDialogOpen}
          vopsScore={vopsScore}
          setVOPSScore={setVOPSScore}
          userInfo={userInfo}
          refetchUsers={refetchUsers}
          enabledWGProfile={enabledAssessments}
          setWGDialogOpen={setWGDialogOpen}
          setOtherAssessmentsDialogOpen={setotherAssessmentsDialogOpen}
        />
        <OtherAssessmentsDialog
          open={otherAssessmentsDialogOpen}
          setOpen={setotherAssessmentsDialogOpen}
          userInfo={userInfo}
          refetchUsers={refetchUsers}
          setWGDialogOpen={setWGDialogOpen}
          setVOPSDialogOpen={setVOPSDialogOpen}
          otherAssessments={otherAssessments}
          setOtherAssessments={setOtherAssessments}
        />
      </div>
    </>
  );
}

UsersList.defaultProps = {
  canEdit: false,
};
