import { Alert, FullScreenLoader, Sidebar } from 'components';
import { Alert as AlertType } from 'components/Alert/types';
import Auth, { ExtendedFunctionComponent, PageProps } from 'components/Auth';
import cookieCutter from 'cookie-cutter';
import { GET_COMPANY_USER_BY_ID, UPDATE_COMPANY_USER } from 'data/company';
import { MeetingsProvider } from 'data/providers';
import { GET_USER_COMPANY_TEAMS } from 'data/teams';
import { Team } from 'data/teams/types';
import USER_COMPANIES_QUERY from 'data/user/get-user-companies';
import { SessionProvider as NextAuthProvider, useSession } from 'next-auth/react';
import Head from 'next/head';
import { NextRouter, useRouter } from 'next/router';
import NProgress from 'nprogress';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { client, ssrCache } from 'src/urqlClient';
import Script from 'next/script';
import 'styles/globals.css';
import 'styles/nprogress.css';
import 'styles/org-chart.css';
import 'styles/rich-text.css';
import 'styles/roadmap.css';
import 'styles/table.css';
import 'styles/carousel.css';
import 'styles/file-upload.css';
import 'styles/date-range-picker.css';
import { Provider as UrqlProvider, useQuery, useMutation } from 'urql';
import { classNames, getTeamData, hasRoadmapPermission, isClient, refreshData } from 'utils';
import { Meeting } from 'data/meetings/types';
import { useMeetings } from 'data/providers/MeetingsProvider';
import MeetingNotesDialog from 'components/MeetingNotes/MeetingNotesDialog';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { ADD_USERS_WALKTHROUGH, ACCOUNT_SETTINGS_WALKTHROUGH, CREATE_MEETING_WALKTHROUGH } from 'config/joy-ride';
import ConfettiExplosion from 'react-confetti-explosion';
import { WalkthroughProvider, useWalkthrough } from 'data/providers/WalkthroughProvider';
import { createPortal } from 'react-dom';
import { useRef } from 'react';
import { RouteHistoryProvider } from 'utils/context/routeHistoryContext';

const joyrideStyles = {
  options: {
    zIndex: 10000,
    fontWeight: 100,
    fontSize: 14,
    letterSpacing: '-0.04em',
  },
  buttonNext: {
    backgroundColor: '#4d80f3',
    color: '#fff',
    border: '1px solid black',
    borderRadius: '8px',
  },
  buttonClose: {
    color: '#000000',
  },
  buttonBack: {
    backgroundColor: '#f36d6d',
    color: '#fff',
    borderRadius: '8px',
    padding: '8px 12px',
    border: '2px solid #333',
    marginRight: '8px',
  },
  buttonSkip: {
    backgroundColor: '#8c8c8c',
    color: '#fff',
    fontWeight: 300,
    fontSize: '16px',
    border: '2px solid black',
    borderRadius: '8px',
  },
  tooltipTitle: {
    fontSize: 20,
    margin: 0,
    letterSpacing: '-0.03em',
    fontWeight: 500,
    color: 'black',
  },
  tooltip: {
    border: '3px solid black',
    borderRadius: '8px',
    padding: '16px',
  },
  floaterStyles: {},
};

// import Banner from 'components/Banner';

type AppProps = {
  Component: ExtendedFunctionComponent;
  pageProps: PageProps;
  router?: NextRouter;
  loading?: boolean;
};

function App({ Component, pageProps, loading }: AppProps): ReactElement {
  const { data: session, status: sessionStatus } = useSession();
  const sessionLoading = sessionStatus === 'loading';
  const router = useRouter();
  const [updatingUser, setUpdatingUser] = useState(false);

  const inviteToken = useMemo(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('s2-invite-token');
    }

    return null;
  }, []);

  const userId = useMemo(() => session?.id, [session]);

  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      handleStop();
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  useEffect(() => {
    let mounted = true;

    async function connectInviteToUser() {
      if (mounted) {
        const _inviteToken = localStorage.getItem('s2-invite-token');
        if (_inviteToken) {
          setUpdatingUser(true);
          const userAdded = await fetch(`/api/add-invited-user?token=${_inviteToken}`);
          console.log(userAdded, 'USER ADDED RESPONSE IN APP.TSX');

          if (userAdded.ok) {
            localStorage.removeItem('s2-invite-token');
            console.log('USER SUCCESSFULLY ADDED, REFRESHING');
            await refreshData(router);
          }
          setUpdatingUser(false);
        }
      }
    }

    if (inviteToken && userId) {
      connectInviteToUser();
    }

    return () => {
      mounted = false;
    };
  }, [inviteToken, userId, router]);

  // TODO - all this should probably be extrapolated into a Provider or wrapper of some sort
  // TODO - maybe we should replace cookie-cutter with localStorage as well
  useEffect(() => {
    if (dismissed) {
      setDismissed(false);
    }
  }, [dismissed]);

  let companiesCookie;
  if (isClient()) {
    companiesCookie = cookieCutter.get(`companies`);
  }
  const companiesCookieJSON = companiesCookie && JSON.parse(companiesCookie);
  const companyCookie = companiesCookieJSON?.[router.query.company as string];

  const alert = useMemo(() => {
    let isDismissed = false;
    if (router.query.company && pageProps.expired !== false) {
      const _alert: AlertType = {
        id: 'subscription_expired',
        message: `To access your '${pageProps.company_name}' account, please update your billing information. ${
          pageProps.isAdmin ? 'Go to the billing page to resubscribe.' : 'Please contact a company administrator.'
        }`,
        dismissable: true,
        type: 'warning',
        action: pageProps.isAdmin
          ? {
              label: 'Go to Billing',
              onClick: () => router.push(`/company/${router.query.company}/settings/billing`),
            }
          : undefined,
      };

      const cookieAlert = companyCookie?.alerts?.find((__alert) => __alert.id === _alert.id);
      isDismissed = cookieAlert?.dismissed;

      if (!cookieAlert && isClient()) {
        cookieCutter.set(
          `companies`,
          JSON.stringify({
            ...companiesCookieJSON,
            [router.query.company as string]: {
              ...companyCookie,
              alerts: [
                ...(companyCookie?.alerts || []).filter((__alert) => __alert.id !== _alert.id),
                {
                  id: _alert.id,
                  dismissed: false,
                },
              ],
            },
          }),
          { path: '/' },
        );
      }

      return isDismissed ? null : _alert;
    }
  }, [pageProps, router, companiesCookieJSON, companyCookie]);

  const companyId = router.query.company;
  const [teamsRes, refetchTeams] = useQuery({
    query: GET_USER_COMPANY_TEAMS,
    variables: { user_id: session?.id, company_id: companyId },
    pause: pageProps.hideSidebar || !session || sessionLoading || !companyId,
  });
  const teams: Team[] = teamsRes.data?.teams || [];

  // Display meeting note popup
  const [meetings] = useMeetings();
  let activeMeeting: Meeting;
  if (isClient() && meetings) {
    activeMeeting = meetings?.[Number(router.query.team)];
  }
  const isMeetingDetail = router.asPath.includes('/meetings') && router.query.id;

  return (
    <div className={classNames(pageProps.forPrint ? '' : 'h-screen w-screen flex overflow-hidden', 'bg-white')}>
      <Head>
        <title>{pageProps.pageTitle ? `${pageProps.pageTitle} | S2 Sync` : 'S2 Sync'}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {/* eslint-disable-next-line */}
      {!pageProps.hideSidebar ? <Sidebar teams={teams} {...pageProps} /> : null}
      <div className="flex flex-col flex-1 overflow-hidden">
        {/* <Banner /> */}
        {alert ? (
          <Alert
            alert={alert}
            onDismiss={() => {
              if (isClient()) {
                cookieCutter.set(
                  `companies`,
                  JSON.stringify({
                    ...companiesCookieJSON,
                    [router.query.company as string]: {
                      ...companyCookie,
                      alerts: [
                        ...(companyCookie?.alerts || []).filter((_alert) => _alert.id !== alert.id),
                        {
                          id: alert.id,
                          dismissed: true,
                        },
                      ],
                    },
                  }),
                  { path: '/' },
                );
              }
              setDismissed(true);
            }}
          />
        ) : null}
        {/* eslint-disable-next-line */}
        <Component {...pageProps} refetch={{ userTeams: refetchTeams }} />
        {activeMeeting && activeMeeting?.display_meeting_note && !isMeetingDetail ? (
          <MeetingNotesDialog meeting={activeMeeting} />
        ) : null}
      </div>

      <FullScreenLoader open={loading || updatingUser} opaque />
    </div>
  );
}

function getProps({ company, companyUser, pageProps }) {
  const props = { ...pageProps };
  if (props?.urqlState) {
    ssrCache.restoreData(props?.urqlState);
  }

  props.company_name = company?.name;
  props.expired = company && company.expired !== false;
  props.isAdmin = ['Admin'].includes(companyUser?.company_users?.[0]?.role?.name);
  props.profileData = {
    enabledWGProfile: company?.enabled_wg_profile,
    enabledVOPSProfile: company?.enabled_vops_profile,
  };
  props.enabledAssessments = company?.enabled_assessments;

  if (isClient()) {
    const team = getTeamData();

    if (team && (team.teamId !== props.team || team.companyId !== props.company)) {
      props.team = team.teamId;
      props.company = team.companyId;
    }
  }

  return props;
}

function checkRedirects({ router, sessionStatus, Component, props, teams }) {
  const team = getTeamData();
  if (sessionStatus === 'unauthenticated' && Component?.auth) {
    router.push(`/auth/signin`);
    return true;
  } else if (props.expired && Component?.redirectIfExpired) {
    router.push(`/company/${router.query.company}/settings/billing`);
    return true;
  } else if (!props.isAdmin && Component?.restricted) {
    router.push(`/company/${router.query.company}/roadmap`);
    return true;
  } else if (team && (router.route === '/' || router.route === '/company/[company]')) {
    if (teams !== undefined && !hasRoadmapPermission(teams)) {
      router.push(`/company/${team.companyId}/team/${team.teamId}`);
    } else {
      router.push(`/company/${team.companyId}/roadmap`);
    }

    return true;
  }

  return false;
}

const DAYS_30 = 30 * 24 * 60 * 60 * 1000;

function storeTeam({ companyId, teamId }) {
  if (isClient()) {
    localStorage.setItem('team', JSON.stringify({ companyId, teamId, expires: Date.now() + DAYS_30 }));
  }
}

function AppWrapper({ pageProps, Component }) {
  const router = useRouter();
  const [run, setRun] = useState(false);
  // const [currentWalktrhough, setCurrentWalkthorugh] = useState<Walkthrough>(ADD_USERS_WALKTHROUGH);
  const [isExploding, setIsExploding] = useState(false);
  const { data: session, status: sessionStatus } = useSession();
  const authenticated = sessionStatus === 'authenticated';
  const [isMounted, setIsMounted] = useState(false);
  const [companyUserId, setCompanyUserId] = useState<number | undefined>();

  const [isMeetingsWalkthroughCompleted, setIsMeetingsWalkthroughCompleted] = useState<boolean>(true);
  const [isUsersWalkthroughCompleted, setIsUsersWalkthroughCompleted] = useState<boolean>(true);
  const [isSettingsWalkthroughCompleted, setIsSettingsWalkthroughCompleted] = useState<boolean>(true);

  const { setRunningWalkthrough, setCurrentStep, runningWalkthrough, currentStep } = useWalkthrough();

  const [, updateCompanyUser] = useMutation(UPDATE_COMPANY_USER);

  const previousPathnameRef = useRef(router.pathname);
  const routeCompany = router.query.company;
  const routeTeam = router.query.team;

  let team = getTeamData();
  let teamId = routeTeam || team?.teamId;

  const [companiesRes] = useQuery({
    query: USER_COMPANIES_QUERY,
    variables: { userId: session?.id },
    pause: !session,
  });

  const { company, companyId } = useMemo(() => {
    const companies = (companiesRes?.data?.company_users || []).map(({ company: _company }) => _company);
    const companyIds = companies.map((_company) => _company.id);

    let _companyId = routeCompany || team?.companyId;

    if (!companiesRes.fetching && (!_companyId || !companyIds.includes(Number(_companyId)))) {
      _companyId = companies[0]?.id;
    }
    return { company: companies.find(({ id }) => id === Number(_companyId)), companyId: _companyId };
  }, [companiesRes, routeCompany, team?.companyId]);

  const [teamsRes] = useQuery({
    query: GET_USER_COMPANY_TEAMS,
    variables: {
      company_id: companyId,
      user_id: session?.id,
    },
    pause: !isClient() || !companyId,
  });
  const teams = teamsRes?.data?.teams;

  if (isClient()) {
    // Set redirect URL if user accesses a company URL while not signed in
    if (!authenticated && router.asPath !== '/company/create' && router.asPath.startsWith('/company')) {
      cookieCutter.set('redirectUrl', router.asPath, { path: '/' });
    }

    if (authenticated && !router.asPath.startsWith('/auth')) {
      cookieCutter.set('redirectUrl', '', { expires: new Date(0), path: '/' });
    }

    if (!teamsRes.fetching && teams !== undefined && authenticated) {
      const teamIds = teams.map((_team) => _team.id);

      teamId = teamId ?? team?.teamId;

      // If user goes to a route with a team ID for a team they aren't in
      // Don't store that team ID
      if (!teamIds.includes(Number(teamId))) {
        teamId = null;
        localStorage.removeItem('team');
      }

      if (routeTeam && routeCompany) {
        storeTeam({ companyId, teamId });
      }

      // Recheck team data in localStorage
      team = getTeamData();
      if (!team && teams[0]) {
        storeTeam({ companyId, teamId: teams[0]?.id });
      }
    }
  }

  const [companyUserRes, refetchCompanyUser] = useQuery({
    query: GET_COMPANY_USER_BY_ID,
    variables: {
      company_id: companyId,
      user_id: session?.id,
    },
    pause: !authenticated || !companyId || !Component?.company,
  });
  const companyUser = companyUserRes?.data;

  useEffect(() => {
    const company_user = companyUserRes?.data?.company_users[0];
    if (company_user) {
      setIsMeetingsWalkthroughCompleted(company_user.meetings_walkthrough_completed);
      setIsSettingsWalkthroughCompleted(company_user.settings_walkthrough_completed);
      setIsUsersWalkthroughCompleted(company_user.users_walkthrough_completed);

      setCompanyUserId(company_user.id);
    }
  }, [companyUserRes]);

  useEffect(() => {
    // Set `isMounted` to true only when the component is mounted in the browser
    setIsMounted(true);
  }, []);

  useEffect(() => {
    // Helper function to initialize the walkthrough
    const initializeWalkthrough = (walkthrough, step = 0) => {
      setRunningWalkthrough(walkthrough);
      setCurrentStep(step);
      setRun(true);
    };

    switch (router.pathname) {
      // case '/company/[company]/roadmap':
      //   console.log('Initializing Account Settings Walkthrough');
      //   initializeWalkthrough(ACCOUNT_SETTINGS_WALKTHROUGH, 500);
      //   break;

      case '/company/[company]/settings/users':
        if (!isUsersWalkthroughCompleted) {
          initializeWalkthrough(ADD_USERS_WALKTHROUGH, 0);
        }
        break;

      case '/company/[company]/team/[team]/meetings':
        if (!isMeetingsWalkthroughCompleted) {
          initializeWalkthrough(CREATE_MEETING_WALKTHROUGH, 0);
        }
        break;

      // default:
      //   // If the page does not match any specific walkthrough, reset the state
      //   setRun(false);
      //   setCurrentWalkthorugh(null);
      //   setCurrentStep(0);
      //   break;
    }
  }, [router.pathname, isMeetingsWalkthroughCompleted, isUsersWalkthroughCompleted, isSettingsWalkthroughCompleted]);

  useEffect(() => {
    if (router.pathname === '/company/[company]/settings/users' && !isUsersWalkthroughCompleted) {
      setRun(true);
    } else if (
      router.pathname === '/company/[company]/settings/users/add' &&
      !isUsersWalkthroughCompleted &&
      runningWalkthrough === ADD_USERS_WALKTHROUGH
    ) {
      setRun(true);
    }

    if (router.pathname === '/company/[company]/team/[team]/meetings' && !isMeetingsWalkthroughCompleted) {
      setRun(true);
    } else if (
      router.pathname === '/company/[company]/team/[team]/meetings/new' &&
      !isMeetingsWalkthroughCompleted &&
      runningWalkthrough === CREATE_MEETING_WALKTHROUGH
    ) {
      setRun(true);
    }
  }, [router, isMeetingsWalkthroughCompleted, isUsersWalkthroughCompleted, isSettingsWalkthroughCompleted]);

  const props = useMemo(() => {
    return getProps({ company, companyUser, pageProps });
  }, [pageProps, company, companyUser]);

  let loading =
    sessionStatus === 'loading' ||
    (router.asPath.startsWith('/auth') && authenticated) ||
    companyUserRes?.fetching ||
    companiesRes?.fetching ||
    teamsRes?.fetching;

  if (isClient()) {
    loading = loading || checkRedirects({ sessionStatus, router, Component, props, teams });
  }

  const handleWalkthroughCompletion = async () => {
    switch (runningWalkthrough) {
      case CREATE_MEETING_WALKTHROUGH:
        if (companyUserId) {
          await updateCompanyUser({
            ids: [companyUserId],
            _set: {
              meetings_walkthrough_completed: true,
            },
          });
          await refetchCompanyUser();
        }
        break;
      case ADD_USERS_WALKTHROUGH:
        if (companyUserId) {
          await updateCompanyUser({
            ids: [companyUserId],
            _set: {
              users_walkthrough_completed: true,
            },
          });
          await refetchCompanyUser();
        }
        break;
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, action, index, type } = data;
    console.log({ data });
    setCurrentStep(index);

    if (runningWalkthrough === ADD_USERS_WALKTHROUGH) {
      if (index === 0 && action === ACTIONS.NEXT) {
        router.push(`/company/${router.query.company}/settings/users/add`);
        return;
      }
    }

    if (runningWalkthrough === CREATE_MEETING_WALKTHROUGH) {
      if (index === 0 && action === ACTIONS.NEXT) {
        router.push(`/company/${router.query.company}/team/${router.query.team}/meetings/new`);
        return;
      }
    }

    if (
      runningWalkthrough === ADD_USERS_WALKTHROUGH &&
      status === STATUS.FINISHED &&
      type === 'tour:end' &&
      router.pathname === '/company/[company]/settings/users/add'
    ) {
      setRun(false);
      setIsExploding(true);
      handleWalkthroughCompletion();
    }

    if (
      runningWalkthrough === CREATE_MEETING_WALKTHROUGH &&
      status === STATUS.FINISHED &&
      type === 'tour:end' &&
      router.pathname === '/company/[company]/team/[team]/meetings/new'
    ) {
      setRun(false);
      setIsExploding(true);
      handleWalkthroughCompletion();
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && action !== ACTIONS.CLOSE) {
      setRun(false);
      // handleWalkthroughCompletion();
    }
  };

  const joyride = (
    <Joyride
      steps={runningWalkthrough?.steps}
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton
      run={run}
      callback={handleJoyrideCallback}
      styles={joyrideStyles}
    />
  );

  return companyUserRes?.fetching ? (
    <FullScreenLoader opaque color="text-primary" open={companyUserRes?.fetching} />
  ) : (
    <>
      {Component.auth ? (
        <MeetingsProvider>
          <Auth component={Component} pageProps={props}>
            {/* {isMounted && createPortal(joyride, document.body)} */}
            <Joyride
              steps={runningWalkthrough?.steps}
              continuous
              scrollToFirstStep
              showProgress
              showSkipButton
              run={run}
              callback={handleJoyrideCallback}
              styles={joyrideStyles}
            />
            <>
              {isExploding && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '120%',
                    pointerEvents: 'none',
                    zIndex: 1000,
                  }}
                >
                  <ConfettiExplosion
                    force={0.4}
                    width={1500}
                    duration={4000}
                    particleCount={150}
                    colors={[
                      '#4d80f3', // Very light shade
                      '#E90A8B', // Lighter shade
                      '#E3B858', // Original color
                    ]}
                    onComplete={() => {
                      setIsExploding(false);
                    }}
                  />
                </div>
              )}
            </>
            <App Component={Component} pageProps={props} loading={loading} />
          </Auth>
        </MeetingsProvider>
      ) : (
        <App Component={Component} pageProps={props} loading={loading} />
      )}
    </>
  );
}

function MyApp({ Component, pageProps = {}, router }: AppProps): ReactElement {
  return (
    <>
      <NextAuthProvider session={pageProps.session}>
        <RouteHistoryProvider>
          <WalkthroughProvider>
            <UrqlProvider value={client}>
              <AppWrapper pageProps={pageProps} Component={Component} />
            </UrqlProvider>
          </WalkthroughProvider>
        </RouteHistoryProvider>
      </NextAuthProvider>
      {pageProps.session ? (
        <>
          <Script id="pendo-tracking-code" strategy="lazyOnload">
            {`
          (function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

        // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
        // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
        // Call this function after users are authenticated in your app and your visitor and account id values are available
        // Please use Strings, Numbers, or Bools for value types.
        pendo.initialize({
            visitor: {
                id:              '${pageProps.session?.id}',   // Required if user is logged in, default creates anonymous ID
                email:  '${pageProps.session?.user?.email}',      // Recommended if using Pendo Feedback, or NPS Email
                full_name: '${pageProps.session?.user?.name}',   // Recommended if using Pendo Feedback
                // role:         // Optional
                team: '${router.query.team}',

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            account: {
                id:           '${router.query.company}' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                // name:         // Optional
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
})('575c7df6-fc18-4cf8-4697-be0ad4a17c20');
        `}
          </Script>
        </>
      ) : null}
    </>
  );
}

export default MyApp;
